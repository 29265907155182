
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import Notify from '@reedsy/studio.shared/services/notify/notify';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class ConfirmCancelSubscriptionModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public readonly cancelable = true;

  @NotifyError('Cannot cancel subscription')
  public async cancelSubscription(): Promise<void> {
    await this.$subscription.cancelSubscription();
    Notify.success({message: 'Subscription cancelled'});
  };
}
