import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-justified" }
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = { class: "flex-top" }
const _hoisted_4 = { class: "font-weight-bold" }
const _hoisted_5 = { class: "flex-top" }
const _hoisted_6 = {
  key: 0,
  class: "flex-centered"
}
const _hoisted_7 = { id: "stripe-payment-method" }
const _hoisted_8 = {
  key: 1,
  class: "flex-top payment-form"
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconArrowLeft = _resolveComponent("VuiIconArrowLeft")!
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!
  const _component_SubscriptionPriceBreakdown = _resolveComponent("SubscriptionPriceBreakdown")!
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
    id: _ctx.id,
    class: "subscription-payment-modal equal-columns"
  }, {
    title: _withCtx(() => [
      _createElementVNode("rbe-subscription-back", null, [
        _createElementVNode("button", {
          type: "button",
          class: "button-with-icon",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToPreview && _ctx.backToPreview(...args)))
        }, [
          _createVNode(_component_VuiIconArrowLeft),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Go back", -1))
        ])
      ])
    ]),
    right: _withCtx(() => [
      (_ctx.shouldShowPaymentSetup)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            ref: "payment-form",
            class: "flex-top payment-form",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.subscribe && _ctx.subscribe(...args)), ["prevent"]))
          }, [
            _createVNode(_component_Panel, { class: "payment-method-panel" }, {
              default: _withCtx(() => [
                _createElementVNode("rbe-payment-methods-selector", _hoisted_5, [
                  (!_ctx.isAttached)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_VuiLoadingIndicator, { ref: "loader" }, null, 512)
                      ]))
                    : _createCommentVNode("", true),
                  _cache[14] || (_cache[14] = _createTextVNode()),
                  _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
                    [_vShow, _ctx.isAttached]
                  ])
                ])
              ]),
              _: 1
            }),
            _cache[16] || (_cache[16] = _createTextVNode()),
            _createVNode(_component_LoadingButton, {
              ref: "subscribe-button",
              loading: _ctx.isProcessingPayment,
              disabled: !_ctx.clientSecret,
              class: "button accent-premium",
              type: "submit"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("\n          Subscribe & enable add-ons\n        ")
              ])),
              _: 1
            }, 8, ["loading", "disabled"])
          ], 544))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_Panel, { class: "payment-method-panel" }, {
              default: _withCtx(() => [
                _cache[17] || (_cache[17] = _createTextVNode("\n          \n          We will charge using your default payment method. You can control them\n          ")),
                _createElementVNode("a", {
                  ref: "payment-settings-link",
                  href: _ctx.billingPortalUrl,
                  target: "_blank",
                  class: "link"
                }, "\n            here", 8, _hoisted_9),
                _cache[18] || (_cache[18] = _createTextVNode(".\n        "))
              ]),
              _: 1
            }),
            _cache[20] || (_cache[20] = _createTextVNode()),
            _createVNode(_component_LoadingButton, {
              ref: "update-subscription-button",
              loading: _ctx.isProcessingPayment,
              class: "button accent-premium",
              onClick: _ctx.updateSubscription
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode("\n          Update subscription\n        ")
              ])),
              _: 1
            }, 8, ["loading", "onClick"])
          ]))
    ]),
    default: _withCtx(() => [
      _cache[21] || (_cache[21] = _createTextVNode()),
      _createElementVNode("rbe-subscription-payment", null, [
        _cache[10] || (_cache[10] = _createElementVNode("rbe-payment-section", null, [
          _createElementVNode("h1", null, "\n          Confirm your subscription\n        "),
          _createTextVNode(),
          _createElementVNode("p", null, "\n          Please enter your card details to enable the add-ons you selected.\n        ")
        ], -1)),
        _cache[11] || (_cache[11] = _createTextVNode()),
        _createElementVNode("rbe-payment-section", null, [
          _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Billing frequency", -1)),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createElementVNode("rbe-selected-product", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.intervalTitle), 1),
            _cache[4] || (_cache[4] = _createTextVNode()),
            _createElementVNode("span", {
              class: _normalizeClass(["reedsy-accented interval-discount", {'neutral-highContrast font-weight-normal': _ctx.interval === 'month'}])
            }, _toDisplayString(_ctx.interval === 'year' ? 'Annual saving applied' : 'No discount'), 3)
          ])
        ]),
        _cache[12] || (_cache[12] = _createTextVNode()),
        _createElementVNode("rbe-payment-section", null, [
          _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Your subscription", -1)),
          _cache[9] || (_cache[9] = _createTextVNode()),
          _createElementVNode("rbe-selected-products", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeProductsInfo, (product) => {
              return (_openBlock(), _createElementBlock("rbe-selected-product", {
                key: product,
                class: "flex-justified"
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(product.title), 1),
                _cache[7] || (_cache[7] = _createTextVNode()),
                _createVNode(_component_SubscriptionPrice, {
                  price: _ctx.price.products[product.product].price,
                  currency: _ctx.price.currency,
                  interval: _ctx.price.interval
                }, null, 8, ["price", "currency", "interval"])
              ]))
            }), 128))
          ])
        ]),
        _cache[13] || (_cache[13] = _createTextVNode()),
        _createVNode(_component_SubscriptionPriceBreakdown, {
          "calculated-price": _ctx.price,
          compact: ""
        }, null, 8, ["calculated-price"])
      ]),
      _cache[22] || (_cache[22] = _createTextVNode())
    ]),
    _: 1
  }, 8, ["id"]))
}