
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';
import ModalCloseButton from '@reedsy/studio.shared/components/modals/components/modal-close-button.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@Component({
  components: {
    ModalCloseButton,
    PlainModal,
    PremiumIcon,
  },
})
export default class SubscriptionBaseModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  @Prop({required: false})
  public title: string;

  @Prop({required: false})
  public subtitle: string;

  public get isMobile(): boolean {
    return this.$browser.screen.lessThan.md;
  }

  public closeAll(): void {
    this._sharedModals.CLOSE_ALL();
  }
}
