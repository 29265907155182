
import {Component, mixins, Prop, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import PeriodToggle from './period-toggle.vue';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';
import MultipleSaving from '@reedsy/studio.shared/components/modals/components/subscription/multiple-saving.vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {PropType} from 'vue';
import {ISubscriptionFeaturesModalArgs} from './subscription-features-modal-args.interface';
import FeatureSummaries from './feature-summaries.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {objectKeys} from '@reedsy/utils.object';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import {DEFAULT_BILLING_INTERVAL} from './default-billing-interval';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import SubscriptionPrice from '@reedsy/studio.shared/components/subscriptions/subscription-price.vue';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {FEATURE_SUMMARIES} from './feature-summaries';

@Component({
  components: {
    AnnualSaving,
    PeriodToggle,
    SubscriptionBaseModal,
    FeatureSummaries,
    SubscriptionPrice,
    MultipleSaving,
  },
})
export default class SubscriptionFeatures extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionFeaturesModalArgs>, default: {}})
  public context: ISubscriptionFeaturesModalArgs;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  public readonly cancelable = true;
  public interval: IBillingInterval = DEFAULT_BILLING_INTERVAL;

  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>> = {};
  public calculatedPrice: ICalculatePriceResponse = null;
  public prices: IPriceOptionsResponse = null;

  public get billAnnually(): boolean {
    return this.interval === 'year';
  }

  public set billAnnually(billAnnually: boolean) {
    this.interval = billAnnually ? 'year' : 'month';
  }

  public get hasSelectedAnything(): boolean {
    return Object.values(this.selectedProducts).some(Boolean);
  }

  public get hasMultipleOfferings(): boolean {
    return FEATURE_SUMMARIES.filter((summary) => summary.product).length > 1;
  }

  public get title(): string {
    return this.context.isPaywall ? 'Upgrade to access this feature!' : 'Ready to upgrade?';
  }

  public get discount(): number {
    if (!this.calculatedPrice) return 0;
    const discount = this.calculatedPrice.totalDiscountsAmount / this.calculatedPrice.totalBeforeDiscounts;
    return Math.round(discount * 100);
  }

  public async created(): Promise<void> {
    this.prices = await this.$subscription.fetchPrice();
    const products = new Set(this.context.products);
    this.selectedProducts = {};
    for (const product of products) this.selectedProducts[product] = true;
  }

  @Watch('interval')
  @Watch('selectedProducts', {deep: true, immediate: true})
  public async subscriptionUpdated(): Promise<void> {
    this.calculatedPrice = null;
    if (!this.hasSelectedAnything) return;

    const interval = this.interval;
    const products = objectKeys(this.selectedProducts)
      .filter((product) => this.selectedProducts[product]);
    this.calculatedPrice = await this.$subscription.calculateSubscriptionPrice({
      interval,
      products,
    });
  }

  public continueToPayment(): void {
    this.$subscriptionModal.openPayment({
      price: this.calculatedPrice,
    });
  }
}
