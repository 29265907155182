import Clipboard from '@reedsy/quill/modules/clipboard';
import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import Delta from '@reedsy/quill-delta';
import {styleMatcher} from './matchers/style-matcher';
import {config} from '@reedsy/studio.shared/config';
import {addLists} from './add-lists';
import {ClipboardSource} from '@reedsy/studio.isomorphic/services/quill/modules/clipboard/clipboard-source';
import Scroll from '@reedsy/quill/blots/scroll';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import smartTextOp from '@reedsy/studio.shared/utils/smart-text/smart-text-op';
import Endnotes from '@reedsy/studio.shared/services/quill/modules/endnotes';
import {ENDNOTE_CONTENT_DATASET_NAME} from './endnote-content-attribute-name';
import Endnote from '@reedsy/studio.shared/services/quill/formats/endnote';
import {ClipboardMatcher} from './matchers/i-clipboard-matcher';
import {ClipboardOptions} from './i-clipboard-options';
import {ClipboardSelector} from './i-clipboard-selector';

const logger = loggerFactory.create('ReedsyClipboard');

const EVENTS = {
  AFTER_PASTE: 'reedsy-after-paste',
};

const TAGS = {
  REEDSY_CLIPBOARD: 'reedsy-clipboard',
  BOOK_ID: 'book-id',
};

export interface IClipboardData {
  html: string;
  text: string;
}

export default class ReedsyClipboard extends Clipboard {
  public static readonly events = EVENTS;

  private _isCopying = false;
  private _pasteSource: ClipboardSource = null;

  public constructor(quill: Quill, options: ClipboardOptions) {
    super(quill, options);
    // Prepend to take precedence over the blot matcher
    this.prependMatcher(Node.ELEMENT_NODE, styleMatcher);
  }

  public get isCopying(): boolean {
    return this._isCopying;
  }

  public get pasteSource(): ClipboardSource {
    return this._pasteSource;
  }

  private get bookId(): string {
    const options: any = this.quill.options;
    return options.metadata.bookId;
  }

  public override onCaptureCopy(event: ClipboardEvent, isCut?: boolean): void {
    this._isCopying = true;
    super.onCaptureCopy(event, isCut);
    this._isCopying = false;
  }

  public override onCapturePaste(event: ClipboardEvent): void {
    this._pasteSource = this.parsePasteSource(event);
    super.onCapturePaste(event);
    this._pasteSource = null;
    this.quill.emitter.emit(EVENTS.AFTER_PASTE, event);
  }

  public override onCopy(range: Range, isCut?: boolean): IClipboardData {
    const data = super.onCopy(range, isCut);

    const meta = document.createElement('meta');
    meta.setAttribute(TAGS.REEDSY_CLIPBOARD, 'true');
    meta.setAttribute(TAGS.BOOK_ID, this.bookId);
    data.html = meta.outerHTML + data.html;
    data.html = this.addEndnotesContent(data.html);
    return data;
  }

  public addEndnotesContent(html: string): string {
    const endnotesModule = this.quill.getModule('endnotes') as Endnotes;
    if (!endnotesModule) return html;

    const body = document.createElement('body');
    body.innerHTML = html;

    Array.from(
      body.querySelectorAll<HTMLElement>(`.${Endnote.className}`),
    ).forEach((endnote) => this.addEndnoteContent(endnote));
    return body.innerHTML;
  }

  public addEndnoteContent(endnote: HTMLElement): void {
    const endnotesModule = this.quill.getModule('endnotes') as Endnotes;
    const endnoteId = endnote.dataset[Endnote.blotName];
    const endnoteContent = JSON.stringify(endnotesModule.options.getContents(endnoteId) || []);
    endnote.dataset[ENDNOTE_CONTENT_DATASET_NAME] = endnoteContent;
  }

  public override onPaste(range: Range, data: IClipboardData): void {
    if (range.length) {
      // Let's ensure we delete any selected contents before the insertion. In theory
      // Quill should do this for us. However, there are certain cases where the Delta
      // the Quill clipboard module's Delta doesn't match the actual change Delta (eg
      // when pasted text has inline styling applied), and then it attempts to diff
      // the document instead of apply a "sensible" Delta.
      const deleteSelection = new Delta().retain(range.index).delete(range.length);
      this.quill.updateContents(deleteSelection, Quill.sources.USER);
    }
    super.onPaste({index: range.index, length: 0}, data);
  }

  public override addMatcher(selector: ClipboardSelector, matcher: ClipboardMatcher): void {
    matcher = this.wrapMatcher(selector, matcher);
    super.addMatcher(selector, matcher);
  }

  public prependMatcher(selector: ClipboardSelector, matcher: ClipboardMatcher): void {
    matcher = this.wrapMatcher(selector, matcher);
    this.matchers.unshift([selector, matcher]);
  }

  public override convert(data: IClipboardData, formats: Record<string, unknown>): Delta {
    if (config.env.development) {
      try {
        const ops = JSON.parse(data.text);
        if (Array.isArray(ops)) return new Delta(ops);
      } catch {
        // Ignore JSON parse error - it wasn't JSON we pasted
      }
    }

    if (this.quill.root.contentEditable === 'plaintext-only') {
      if (!data.text) {
        const doc = new DOMParser().parseFromString(data.html, 'text/html');
        data.text = doc.body.textContent;
      }
      data.html = '';
    }

    if (!data.html) {
      data.html = (data.text || '')
        .split('\n')
        .map((line) => `<p>${line}</p>`)
        .join('');
    }

    let delta = super.convert(data, formats);
    const smartText = smartTextOp(delta);
    if (smartText) delta = delta.compose(smartText);
    return addLists(delta);
  }

  private parsePasteSource(event: ClipboardEvent): ClipboardSource {
    const html = event.clipboardData.getData('text/html');
    if (!html) return ClipboardSource.External;

    const doc = new DOMParser().parseFromString(html, 'text/html');
    const meta = doc.querySelector(`meta[${TAGS.REEDSY_CLIPBOARD}]`);
    if (!meta) return ClipboardSource.External;

    const bookId = meta.getAttribute(TAGS.BOOK_ID);
    if (bookId === this.bookId) return ClipboardSource.SameBook;
    return ClipboardSource.External;
  }

  private wrapMatcher(selector: string | number, matcher: ClipboardMatcher): ClipboardMatcher {
    function wrappedMatcher(node: Node, delta: Delta, scroll: Scroll): Delta {
      try {
        return matcher(node, delta, scroll);
      } catch (error) {
        const element = document.createElement('div');
        element.appendChild(node.cloneNode(true));

        logger.error(error, {
          data: {
            selector,
            node: element.innerHTML,
            nodeType: node.nodeType,
            nodeClass: node.constructor.name,
          },
        });
        throw error;
      }
    }

    return wrappedMatcher;
  }
}
