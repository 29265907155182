import {IBillingInterval, SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {IPriceOfferings} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {objectKeys} from '@reedsy/utils.object';
import {SubscriptionProduct} from '@reedsy/utils.subscription';

export interface IOffering {
  interval: IBillingInterval;
  currency: SupportedCurrency;
  price: number;
  productName: SubscriptionProduct;
}

export function getAllOfferings(offeringsObject: IPriceOfferings): IOffering[] {
  const offerings: IOffering[] = [];

  if (!offeringsObject) return offerings;

  for (const productName of objectKeys(offeringsObject)) {
    for (const interval of objectKeys(offeringsObject[productName])) {
      const options = offeringsObject[productName][interval];
      for (const option of options) {
        offerings.push({
          ...option,
          interval,
          productName,
        });
      }
    }
  }

  return offerings;
}
