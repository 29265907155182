<template>
  <rbe-price-info
    class="flex-top"
    :class="{compact}"
  >
    <rbe-price-item>
      <span>Subtotal</span>
      <SubscriptionPrice
        :price="subtotal"
        :currency="currency"
        :interval="interval"
      />
    </rbe-price-item>
    <rbe-price-item>
      <span>Discounts</span>
      <SubscriptionPrice
        :price="discount"
        :currency="currency"
        :interval="interval"
      />
    </rbe-price-item>

    <hr>

    <rbe-price-item class="price-headline">
      <span>{{ compact ? 'Total' : 'Your subscription' }}</span>
      <SubscriptionPrice
        :price="total"
        :currency="currency"
        :interval="interval"
      />
    </rbe-price-item>
    <rbe-price-item v-if="nextBillingDate">
      <span>Next billing date</span>
      <span>{{ nextBillingDate }}</span>
    </rbe-price-item>
  </rbe-price-info>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import SubscriptionPrice from './subscription-price.vue';
import {IBillingInterval, SupportedCurrency} from '@reedsy/schemas.editor-collections';

@Component({
  components: {
    SubscriptionPrice,
  },
})
export default class SubscriptionPriceBreakdown extends ClientSharedVue {
  @Prop({type: Object as PropType<ICalculatePriceResponse>, required: false})
  public calculatedPrice: ICalculatePriceResponse;

  @Prop({type: String})
  public nextBillingDate: string;

  @Prop({type: Boolean})
  public compact: boolean;

  public get currency(): SupportedCurrency {
    return this.calculatedPrice?.currency;
  };

  public get interval(): IBillingInterval {
    return this.calculatedPrice?.interval;
  };

  public get subtotal(): number {
    return this.calculatedPrice?.totalBeforeDiscounts;
  }

  public get discount(): number {
    if (!this.calculatedPrice) return;
    return -this.calculatedPrice.totalDiscountsAmount;
  }

  public get total(): number {
    return this.calculatedPrice?.total;
  }
}
</script>

<style lang="scss" scoped>
rbe-price-info {
  gap: $space-sm;

  hr {
    color: var(--reedsy-dividerColor);
    width: 100%;
    height: $border-width;
  }

  rbe-price-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    :deep(.skeleton-loader) {
      max-width: 5rem;
    }

    &.price-headline {
      @include font-family($controls, bold);

      font-size: $font-size-lg;

      :deep(.skeleton-loader) {
        max-width: 6.5rem;
      }
    }
  }

  &.compact {
    hr {
      background-color: var(--reedsy-plain-onDefault);
      height: 0.1875rem;
    }
  }
}
</style>
