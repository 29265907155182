
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {ICalculatePriceResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-calculate-price-response';
import SubscriptionPrice from './subscription-price.vue';
import {IBillingInterval, SupportedCurrency} from '@reedsy/schemas.editor-collections';

@Component({
  components: {
    SubscriptionPrice,
  },
})
export default class SubscriptionPriceBreakdown extends ClientSharedVue {
  @Prop({type: Object as PropType<ICalculatePriceResponse>, required: false})
  public calculatedPrice: ICalculatePriceResponse;

  @Prop({type: String})
  public nextBillingDate: string;

  @Prop({type: Boolean})
  public compact: boolean;

  public get currency(): SupportedCurrency {
    return this.calculatedPrice?.currency;
  };

  public get interval(): IBillingInterval {
    return this.calculatedPrice?.interval;
  };

  public get subtotal(): number {
    return this.calculatedPrice?.totalBeforeDiscounts;
  }

  public get discount(): number {
    if (!this.calculatedPrice) return;
    return -this.calculatedPrice.totalDiscountsAmount;
  }

  public get total(): number {
    return this.calculatedPrice?.total;
  }
}
