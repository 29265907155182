import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-drawer" }
const _hoisted_2 = {
  key: 0,
  ref: "container",
  class: "container"
}
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_VuiIconCross = _resolveComponent("VuiIconCross")!
  const _directive_touch = _resolveDirective("touch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.withOverlay && _ctx.show)
          ? _withDirectives((_openBlock(), _createBlock(_component_Overlay, {
              key: 0,
              onClick: _ctx.hide
            }, null, 8, ["onClick"])), [
              [
                _directive_touch,
                _ctx.closeBySwipe,
                "swipe",
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _createVNode(_Transition, { name: "move-top" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true),
                _cache[1] || (_cache[1] = _createTextVNode()),
                _createElementVNode("button", {
                  type: "button",
                  class: "close-drawer-button button-sm button-icon reedsy-accented translucent",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
                }, [
                  _createVNode(_component_VuiIconCross)
                ])
              ])), [
                [
                  _directive_touch,
                  _ctx.touchStart,
                  "press",
                  { stop: true }
                ],
                [
                  _directive_touch,
                  _ctx.resizeOnMove,
                  "drag",
                  { stop: true }
                ],
                [
                  _directive_touch,
                  _ctx.touchEnd,
                  "release",
                  { stop: true }
                ]
              ]),
              _cache[2] || (_cache[2] = _createTextVNode()),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}