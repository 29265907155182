import Quill from '@reedsy/quill';
import Module from '@reedsy/quill/core/module';
import {Registry} from 'parchment';
import Video from '@reedsy/studio.shared/services/quill/blots/video';
import {videoMatcher} from '@reedsy/studio.shared/services/quill/modules/clipboard/matchers/video-matcher';
import DragAndDrop from '@reedsy/studio.shared/services/quill/modules/drag-and-drop';
import VideoPlaceholder from '@reedsy/studio.shared/services/quill/modules/drag-and-drop/placeholders/video-placeholder';

export default class Videos extends Module {
  public static registerFormats(registry: Registry): void {
    registry.register(Video);
  }

  public constructor(quill: Quill, options: any) {
    super(quill, options);
    Videos.registerFormats(quill.options.registry);

    DragAndDrop.registerModule(quill, {
      placeholderTemplates: {
        [Video.blotName]: VideoPlaceholder,
      },
    });

    quill.clipboard.addMatcher(Node.TEXT_NODE, videoMatcher);
  }
}
