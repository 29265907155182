<template>
  <SubscriptionStartedBase
    :id="id"
    :title="`Your ${config.stripe.trialDays} day trial has started`"
  >
    <template #banner>
      <rbe-trial-started>
        <rbe-toggles>
          <AddOnToggle
            title="Studio Pro"
            :model-value="true"
            :disabled="true"
          />
          <AddOnToggle
            title="Outlining"
            :model-value="true"
            :disabled="true"
          />
        </rbe-toggles>
      </rbe-trial-started>
    </template>

    <span ref="description">
      You’re now on our premium plan. {{ description }}
    </span>
  </SubscriptionStartedBase>
</template>

<script lang="ts">
import {Component, Inject, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import AddOnToggle from './add-on-toggle.vue';
import {config} from '@reedsy/studio.shared/config';
import SubscriptionStartedBase from './subscription-started-base.vue';

@Component({
  components: {
    AddOnToggle,
    SubscriptionStartedBase,
  },
})
export default class SubscriptionTrialStarted extends mixins(ModalMixin, ClientSharedVue) {
  @Inject
  public tourBookUuid: string;

  public readonly config = config;
  public readonly cancelable = true;

  public get description(): string {
    if (this.tourBookUuid) {
      return 'Discover all our features or jump straight into writing.';
    }

    return 'Create a book and jump straight into writing.';
  }
}
</script>

<style lang="scss" scoped>
rbe-toggles {
  $size: 15rem;

  position: relative;
  height: $size;
  width: 21rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $space-sm;
  margin: $space-base auto;

  & > * {
    position: relative;
  }

  &::before {
    @include size($size);

    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--reedsy-neutral-muted);
    border-radius: 50%;
  }
}
</style>
