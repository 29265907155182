import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {config} from '@reedsy/studio.shared/config';
import {injectable, named} from 'inversify';
import {IDirectiveFactory} from '@reedsy/studio.shared/directives/i-directive-factory';
import {Directive} from 'vue';
import {$inject} from '@reedsy/studio.shared/types';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {getProducts, IPaidFeature} from '@reedsy/utils.subscription';

@injectable()
export class PremiumOnly implements IDirectiveFactory {
  public readonly name = 'premium-only';

  public constructor(
    @$inject('StoreModule')
    @named(SharedStoreName.Subscription)
    private $subscription: SharedSubscriptionModule,

    @$inject('SubscriptionModal')
    private $subscriptionModal: ISubscriptionModalService,
  ) {}

  public get directive(): Directive {
    return {
      mounted: (element: HTMLElement, {value}): void => {
        if (!config.features.subscriptions) return;

        element.addEventListener('click', (event) => {
          if (this.$subscription.hasFeature(value as IPaidFeature)) return;
          event.stopImmediatePropagation();
          this.$subscriptionModal.open({products: getProducts(value), isPaywall: true});
        }, {
          // Get there before the @click handler
          capture: true,
        });
      },
    };
  }
}
