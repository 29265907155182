
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import {config} from '@reedsy/studio.shared/config';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import SubscriptionProductPrice from '@reedsy/studio.shared/components/subscriptions/subscription-product-price.vue';

@Component({components: {
  SubscriptionProductPrice,
}})
export default class FeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Boolean, default: false})
  public isPreview: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  public readonly config = config;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }

  public get isActive(): boolean {
    return this.isPreview || this.selected;
  }

  public toggleSelected(): void {
    if (!this.isProduct) return;
    this.selected = !this.selected;
  }
}
