import Quill from '@reedsy/quill';
import Module from '@reedsy/quill/core/module';
import SuggestionsTooltip from '@reedsy/studio.shared/services/quill/modules/mentions/suggestions-tooltip/suggestions-tooltip';
import {ActionsTooltip} from '@reedsy/studio.shared/services/quill/modules/mentions/actions-tooltip/actions-tooltip';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import {Viewport} from '@reedsy/studio.shared/utils/viewport';
import {Registry} from 'parchment';
import MentionBlot, {MENTION_ID} from '@reedsy/studio.shared/services/quill/blots/mentions';
import {MentionEvent} from '@reedsy/studio.shared/services/quill/modules/mentions/events';
import Delta from '@reedsy/quill-delta';
import {EmitterSource} from '@reedsy/quill/core/emitter';
import {testLetter} from '@reedsy/utils.string';
import LiveRange from '@reedsy/studio.shared/services/quill/helpers/live-range';
import {
  SuggestionTooltipClass,
} from '@reedsy/studio.shared/services/quill/modules/mentions/suggestions-tooltip/suggestion-tooltip-class';
import {Range} from '@reedsy/quill/core/selection';
import ReedsyKeyboard from '@reedsy/studio.shared/services/quill/modules/keyboard';
import {microtask} from '@reedsy/utils.timeout';

export default class Mentions extends Module {
  public static registerFormats(registry: Registry): void {
    registry.register(MentionBlot);
  }

  public override options: IMentionsOptions;

  private readonly suggestionsTooltip: SuggestionsTooltip = null;
  private readonly actionsTooltip: ActionsTooltip = null;
  private readonly prefix = '@';

  public constructor(quill: Quill, options?: any) {
    super(quill, options);
    Mentions.registerFormats(quill.options.registry);

    this.quill = quill;
    this.suggestionsTooltip = new SuggestionsTooltip(quill, quill.options.bounds || quill.root);
    this.actionsTooltip = new ActionsTooltip(quill, quill.options.bounds || quill.root);

    quill.on('editor-change', this.onEditorChange.bind(this));
    quill.on(MentionEvent.SuggestionClick, this.insertItem.bind(this));
    quill.on(MentionEvent.SuggestionMouseEnter, this.selectOption.bind(this));
    quill.on(MentionEvent.MentionClick, this.showActionsTooltip.bind(this));
    this.quill.on(MentionEvent.MentionReady, this.setupMention.bind(this));
    this.quill.on(MentionEvent.MentionStateUpdate, this.setupMention.bind(this));

    this.addKeyboardBindings();
  }

  private get selectedOption(): HTMLElement {
    return this.suggestionsTooltip.mentionsList.querySelector(`.${SuggestionTooltipClass.SelectedOption}`);
  }

  private get firstOption(): HTMLElement {
    return this.suggestionsTooltip.mentionsList.querySelector('.ql-mention-option');
  }

  private setupMention(id: string): void {
    this.updateMentionTitle(id);
    this.updateMentionDeletedState(id);
  }

  private async updateMentionTitle(id: string): Promise<void> {
    await microtask();
    const title = this.options.titlesById(id);
    this.quill.emitter.emit(MentionEvent.MentionTitleUpdate, {id, title});
  }

  private async updateMentionDeletedState(id: string): Promise<void> {
    await microtask();
    const deleted = this.options.deletedById(id);
    this.quill.emitter.emit(MentionEvent.MentionDeletedState, {id, deleted});
  }

  private showActionsTooltip(mention: MentionBlot): void {
    const index = this.quill.getIndex(mention);
    this.quill.setSelection(index, 0);

    const url = this.options.assetUrl(mention.id);
    this.actionsTooltip.showTooltip(mention.id, url, this.options.isPinned(mention.id));
  }

  private async onEditorChange(
    _type: string,
    _new: Range | Delta,
    _old: Range | Delta,
    source: EmitterSource,
  ): Promise<void> {
    if (source !== Quill.sources.USER) return;
    this.actionsTooltip.hide();
    await this.toggleDropdown();
  }

  private async toggleDropdown(): Promise<void> {
    const range = this.quill.getSelection();
    if (!range || range.length) return this.hideSuggestions();

    const match = this.possibleMentionMatch(range.index);
    if (!match) return this.hideSuggestions();

    const suggestions = await this.options.suggestions(match.query);
    this.renderList(suggestions);
  }

  private renderList(values: ISuggestion[]): void {
    if (!values.length) {
      this.hideSuggestions();
      return;
    }

    this.suggestionsTooltip.showTooltip(values);
    this.selectOption(this.firstOption);
  }

  private possibleMentionMatch(quillIndex: number): IPossibleMenuMatch {
    if (this.options.singleMode) {
      return {
        atIndex: 0,
        query: this.quill.getText(0),
        fullTextLength: this.quill.getLength(),
      };
    }

    const [leaf, offset] = this.quill.getLeaf(quillIndex);

    const matcher = new RegExp(`(?:^|\\s)(${this.prefix}([^\\s${this.prefix}]*))`, 'g');
    let match: RegExpExecArray;
    let isBeginningOfTheLine = false;
    let startIndex: number;

    // TODO: It could be refactored to use a RegExp indicies feature
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/hasIndices
    // but it's required to use «es2022» as the «target» in the «tsconfig.json» which leads to a lot of errors.
    // So, for now, we have to check the beginning of the link to calculate start and end indexes manually, because
    // of optionally captured «space» symbol before the «@» symbol.
    while (match = matcher.exec(leaf.domNode.textContent)) {
      // We have to check if there is a «space» symbol before the «@» symbol to calculate indexes correctly.
      isBeginningOfTheLine = match[0].startsWith(this.prefix);
      startIndex = isBeginningOfTheLine ? match.index : match.index + 1; // +1 because of «space» symbol.
      const endIndex = startIndex + match[1].length;
      if (startIndex <= offset - 1 && endIndex >= offset) break;
    }

    if (!match) return;

    return {
      atIndex: startIndex,
      query: match[2],
      fullTextLength: match[1].length,
    };
  }

  private insertItem(): void {
    if (!this.selectedOption) return;
    const id = this.selectedOption.dataset[MENTION_ID];

    const range = this.quill.getSelection();
    const [, offset] = this.quill.getLeaf(range.index);

    const match = this.possibleMentionMatch(range.index);

    const mentionQuillIndex = range.index - offset + match.atIndex;

    const delta = new Delta().retain(mentionQuillIndex).delete(match.fullTextLength).insert({mention: id});

    const nextCharacter = this.quill.getText({index: mentionQuillIndex + match.fullTextLength, length: 1});
    const shouldAddSpace = nextCharacter !== ' ' && !this.options.singleMode;

    if (shouldAddSpace) delta.insert(' ');

    this.quill.updateContents(delta, Quill.sources.USER);

    const newCursorPosition = mentionQuillIndex + 2; // +2 because of the mention length + space character.

    this.quill.setSelection(newCursorPosition, Quill.sources.USER);
    this.hideSuggestions();

    if (this.options.singleMode) return;

    const live = new LiveRange(this.quill, {index: mentionQuillIndex + 1, length: 1});

    const onTextChangeAfterInsertionHandler = (newValue: Delta, _oldValue: Delta, source: EmitterSource): void => {
      if (source !== Quill.sources.USER) return;
      const SPACE_LENGTH = ' '.length;
      this.quill.off('text-change', onTextChangeAfterInsertionHandler);
      // LiveRange covers the space after the mention, so we're checking if the user has inserted a new character.
      // If so, the length are changed, and we're going to do updates after insertion.
      // If not, the user changed the other part of the document, and we do not need to do anything.
      const isTargetPointChanged = live.get().length > SPACE_LENGTH;
      if (!isTargetPointChanged) return;
      const targetIndex = live.get().index + SPACE_LENGTH; // +1 to start from the space after the mention.
      this.onTextChangeAfterInsertion(targetIndex);
    };

    this.quill.on('text-change', onTextChangeAfterInsertionHandler);
  }

  // We're listening to the next update by user to check if the user has inserted a punctuation mark after the mention.
  // If so, we're removing the space after the mention.
  private async onTextChangeAfterInsertion(afterSpaceIndex: number): Promise<void> {
    const [leaf] = this.quill.getLeaf(afterSpaceIndex);

    const firstSymbolIsSpace = leaf.domNode.textContent.startsWith(' ');
    if (!firstSymbolIsSpace) return;

    const secondSymbolIsPunctuation = !testLetter(leaf.domNode.textContent[1]);
    if (!secondSymbolIsPunctuation) return;

    const delta = new Delta().retain(afterSpaceIndex - 1).delete(1);
    this.quill.updateContents(delta, Quill.sources.USER);
    await microtask();
    this.quill.setSelection(afterSpaceIndex, Quill.sources.USER);
  }

  private deselectOption(): void {
    this.selectedOption?.classList.remove(SuggestionTooltipClass.SelectedOption);
  }

  private selectOption(element: Element): void {
    this.deselectOption();
    element.classList.add('selected');
    Viewport.scrollIntoView(element, {block: 'nearest'});
  }

  private selectPreviousItem(): void {
    const previous = this.selectedOption.previousElementSibling;
    if (!previous) return;
    this.selectOption(previous);
  }

  private selectNextItem(): void {
    const next = this.selectedOption.nextElementSibling;
    if (!next) return;
    this.selectOption(next);
  }

  private hideSuggestions(): void {
    this.suggestionsTooltip.hide();
    this.deselectOption();
  }

  private addKeyboardBindings(): void {
    const keyboard = this.quill.keyboard as ReedsyKeyboard;
    keyboard.prependBinding({
      key: Key.Enter,
      handler: () => {
        if (!this.selectedOption) return true;
        this.insertItem();
      },
    });

    keyboard.addBinding({
      key: Key.Escape,
      handler: () => {
        this.hideSuggestions();
        return true;
      },
    });

    keyboard.addBinding({
      key: Key.Tab,
      handler: () => {
        if (!this.selectedOption) return true;
        this.selectNextItem();
      },
    });

    keyboard.addBinding({
      key: Key.Tab,
      shiftKey: true,
      handler: () => {
        if (!this.selectedOption) return true;
        this.selectPreviousItem();
      },
    });

    keyboard.addBinding({
      key: Key.ArrowUp,
      handler: () => {
        if (!this.selectedOption) return true;
        this.selectPreviousItem();
      },
    });

    keyboard.addBinding({
      key: Key.ArrowDown,
      handler: () => {
        if (!this.selectedOption) return true;
        this.selectNextItem();
      },
    });
  }
}

export interface ISuggestion {
  id: string;
  title: string;
  image?: string;
}

interface IMentionsOptions {
  singleMode?: boolean;
  suggestions?: (query: string) => Promise<ISuggestion[]>;
  titlesById: (id: string) => string;
  deletedById: (id: string) => boolean;
  pinMention: (assetId: string) => void;
  openMention: (assetId: string) => void;
  isPinned: (assetId: string) => boolean;
  assetUrl: (assetId: string) => string;
}

interface IPossibleMenuMatch {
  atIndex: number;
  query: string;
  fullTextLength: number;
}
