<!-- eslint-disable @stylistic/max-len -->
<template>
  <rbe-multiple-saving class="reedsy-accented">
    <span>
      <!-- TODO: [subscriptions] should we have specific value here? -->
      Save more
    </span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 77 5"
    >
      <path
        fill="currentColor"
        d="M1.3519677 0.98244196L74.750191 0.029218316C75.976692 0.013289694 76.983887 0.99465483 76.999809 2.2211587C76.999939 2.230772 77 2.240386 77 2.25C77 3.4598374 76.019234 4.4406047 74.809395 4.4406047C74.789864 4.4406047 74.770332 4.4403434 74.750809 4.4398208L3.601598 2.5361631C2.7185082 2.5125353 1.8600382 2.2400255 1.125 1.75C0.93246645 1.6216443 0.88044 1.3615122 1.0087957 1.1689786C1.0854338 1.0540215 1.2138181 0.98423612 1.3519677 0.98244196Z"
        fill-rule="evenodd"
      />
    </svg>
  </rbe-multiple-saving>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class MultipleSaving extends ClientSharedVue {
}
</script>

<style lang="scss" scoped>
rbe-multiple-saving {
  @include font-family($controls, bold);

  display: inline-block;
  position: relative;
  color: var(--local-color-emphasis);

  svg {
    position: absolute;
    bottom: -$space-xs;
    left: 0;
    width: 100%;
    color: var(--local-color-default);
  }
}
</style>
