import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "top-bar",
  class: "top-bar"
}
const _hoisted_2 = {
  key: 0,
  class: "alert-bar"
}
const _hoisted_3 = { class: "top-bar-controls" }
const _hoisted_4 = { class: "left-side" }
const _hoisted_5 = { class: "middle ellipsis" }
const _hoisted_6 = { class: "book-title ellipsis" }
const _hoisted_7 = { class: "right-side" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconMenu = _resolveComponent("VuiIconMenu")!
  const _component_OptionButton = _resolveComponent("OptionButton")!
  const _component_MoonIcon = _resolveComponent("MoonIcon")!
  const _component_SunIcon = _resolveComponent("SunIcon")!
  const _component_FontIcon = _resolveComponent("FontIcon")!
  const _component_FontSettingsMenu = _resolveComponent("FontSettingsMenu")!
  const _component_DrawerPopover = _resolveComponent("DrawerPopover")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (_ctx.alertBarIsOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", null, "Access to this preview expires at " + _toDisplayString(_ctx.displayedExpiryDate), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_OptionButton, {
          class: "toggle-left-sidebar-button button",
          onClick: _ctx.toggleLeftSidebar
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VuiIconMenu, { class: "contents-button-icon" }),
            _cache[0] || (_cache[0] = _createTextVNode()),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "contents-button-text hidden-until-md" }, "Contents", -1))
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.bookTitle), 1)
      ]),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _createElementVNode("div", _hoisted_7, [
        _withDirectives((_openBlock(), _createBlock(_component_OptionButton, {
          ref: "toggleDarkModeButton",
          class: "toggle-dark-mode-button button-icon squared",
          onClick: _ctx.toggleDarkMode
        }, {
          default: _withCtx(() => [
            _createVNode(_Transition, {
              name: "toggle-dark-mode",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_ctx.isDarkMode)
                  ? (_openBlock(), _createBlock(_component_MoonIcon, {
                      key: 0,
                      class: "dark-mode-icon"
                    }))
                  : (_openBlock(), _createBlock(_component_SunIcon, {
                      key: 1,
                      class: "light-mode-icon"
                    }))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])), [
          [_directive_tooltip, _ctx.tooltipOptions('Color scheme')]
        ]),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createVNode(_component_DrawerPopover, {
          open: _ctx.fontSettingsMenuIsOpen,
          title: "Font settings",
          onHide: _ctx.hideFontSettingsMenu
        }, {
          trigger: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_OptionButton, {
              class: _normalizeClass(["toggle-font-settings-menu-button button-icon squared", {active: _ctx.fontSettingsMenuIsOpen}]),
              onClick: _ctx.toggleFontSettingsMenu
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FontIcon, { class: "font-settings-menu-icon" })
              ]),
              _: 1
            }, 8, ["class", "onClick"])), [
              [_directive_tooltip, _ctx.tooltipOptions('Font settings')]
            ])
          ]),
          "menu-content": _withCtx(() => [
            _createVNode(_component_FontSettingsMenu)
          ]),
          _: 1
        }, 8, ["open", "onHide"])
      ])
    ])
  ]))
}