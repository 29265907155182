import 'reflect-metadata';
import '@reedsy/studio.shared/polyfills';
import '@reedsy/studio.shared/utils/html/jsx/render-jsx';
import '@reedsy/studio.shared/store/vuex-decorators';
import '@reedsy/studio.shared/style/shared-styles';
import '@reedsy/quill/dist/quill.core.css';
import {createApp} from 'vue';
import BookViewerApp from './BookViewerApp.vue';
import router from './router';
import {bookViewerContainer} from '@reedsy/studio.viewer/inversify.config';
import ErrorHandler from '@reedsy/studio.shared/plugins/error-handler';
import ReedsyVui from '@reedsy/vui';
import VInput from '@reedsy/studio.shared/components/forms/input.vue';
import VTextarea from '@reedsy/studio.shared/components/forms/textarea/textarea.vue';
import Vue3TouchEvents from 'vue3-touch-events';
import FloatingVue from 'floating-vue';
import {registerModalComponents} from '@reedsy/studio.shared/components/modals/register-modal-components';
import {MODAL_COMPONENT_MAP} from './components/modals/modal-component-map';
import {initialiseOsDarkModeListener} from '@reedsy/studio.shared/utils/is-os-dark-mode';

initialiseOsDarkModeListener();
const pluginFactories = bookViewerContainer.getAll('BookViewerStorePlugins');
pluginFactories.forEach((factory) => factory.plugin());
const storeWrapper = bookViewerContainer.get('StoreWrapper');
const {store} = storeWrapper;

const app = createApp(BookViewerApp);

app.use(router);
app.use(store);
app.use(FloatingVue);
app.use(ErrorHandler);
app.use(ReedsyVui);
app.use(Vue3TouchEvents, {
  disableClick: true,
  swipeTolerance: 100,
});
app.component('VInput', VInput);
app.component('VTextarea', VTextarea);
registerModalComponents(app, MODAL_COMPONENT_MAP);

app.mount('#reedsy-book-viewer');
