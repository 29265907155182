/**
 * https://stripe.com/docs/elements/appearance-api?platform=web#supported-css-properties
 */

const BORDER = [
  'border',
  'borderBottom',
  'borderBottomColor',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderBottomStyle',
  'borderBottomWidth',
  'borderColor',
  'borderLeft',
  'borderLeftColor',
  'borderLeftStyle',
  'borderLeftWidth',
  'borderRadius',
  'borderRight',
  'borderRightColor',
  'borderRightStyle',
  'borderRightWidth',
  'borderStyle',
  'borderTop',
  'borderTopColor',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderTopStyle',
  'borderTopWidth',
  'borderWidth',
] as const satisfies ReadonlyArray<keyof CSSStyleDeclaration>;

const TEXT = [
  'color',
  'fontFamily',
  'fontSize',
  'fontVariant',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'textDecoration',
  'textShadow',
  'textTransform',
] as const satisfies ReadonlyArray<keyof CSSStyleDeclaration>;

const SPACING = [
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
] as const satisfies ReadonlyArray<keyof CSSStyleDeclaration>;

const OUTLINE = [
  'outline',
  'outlineOffset',
] as const satisfies ReadonlyArray<keyof CSSStyleDeclaration>;

export const STRIPE_LABEL_CSS_PROPERTIES = [
  ...TEXT,
  ...SPACING,
  'opacity',
  'transition',
] as const satisfies ReadonlyArray<keyof CSSStyleDeclaration>;

export const STRIPE_INPUT_CSS_PROPERTIES = [
  ...BORDER,
  ...TEXT,
  ...SPACING,
  ...OUTLINE,
  'backgroundColor',
  'boxShadow',
  'fill',
  'transition',
] as const satisfies ReadonlyArray<keyof CSSStyleDeclaration>;
