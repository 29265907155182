import {getAppliedStyles} from '@reedsy/studio.shared/utils/html/get-applied-styles';
import {STRIPE_INPUT_CSS_PROPERTIES, STRIPE_LABEL_CSS_PROPERTIES} from './stripe-css-properties';
import {merge, pick} from '@reedsy/utils.object';
import {Stripe, StripeElements, StripeElementsOptions} from '@stripe/stripe-js';

export function getStripeElements(stripe: Stripe, options: StripeElementsOptions): StripeElements {
  const appearance = merge({
    theme: 'stripe',
    rules: {
      '.Label': pick(
        getAppliedStyles('label', {class: 'font-size-sm padding-bottom-1em'}),
        ...STRIPE_LABEL_CSS_PROPERTIES,
      ),
      '.Input': pick(
        getAppliedStyles('input', {type: 'text'}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.Input:focus': pick(
        getAppliedStyles('input', {type: 'text'}, {getFocusStylesByStealingFocus: true}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.Input--invalid': pick(
        getAppliedStyles('input', {type: 'text', class: 'invalid'}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.Input--invalid:focus': pick(
        getAppliedStyles('input', {type: 'text', class: 'invalid focused'}),
        ...STRIPE_INPUT_CSS_PROPERTIES,
      ),
      '.AccordionItem--selected': pick(
        getAppliedStyles('p', {class: 'text-accent accent-premium'}),
        'color',
      ),
      '.Error': pick(
        getAppliedStyles('p', {class: 'xs text-accent accent-danger padding-top-1em'}),
        ...STRIPE_LABEL_CSS_PROPERTIES,
      ),
    },
  }, options.appearance);

  return stripe.elements({
    ...options,
    appearance: appearance,
  } as any);
}
