import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DelayedLoadingIndicator = _resolveComponent("DelayedLoadingIndicator")!
  const _component_FeatureSummaries = _resolveComponent("FeatureSummaries")!
  const _component_CurrencySelector = _resolveComponent("CurrencySelector")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (!_ctx.offerings)
    ? (_openBlock(), _createElementBlock("rbe-loading", _hoisted_1, [
        _createVNode(_component_DelayedLoadingIndicator, { delay: 1_000 })
      ]))
    : (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
        key: 1,
        id: _ctx.id,
        title: `Start your free ${_ctx.config.stripe.trialDays}-day trial`,
        subtitle: "Unlock Studio by test driving our premium add-ons.",
        class: "subscription-trial-modal selector-modal"
      }, {
        left: _withCtx(() => [
          _createVNode(_component_FeatureSummaries, {
            "feature-preview": "",
            prices: _ctx.prices,
            interval: "month"
          }, null, 8, ["prices"])
        ]),
        right: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("\n      TODO: [subscriptions] Add videos\n    ")
        ])),
        "footer-prepend": _withCtx(() => [
          _createVNode(_component_CurrencySelector, {
            modelValue: _ctx.selectedCurrency,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
            "available-currencies": _ctx.availableCurrencies
          }, null, 8, ["modelValue", "available-currencies"])
        ]),
        action: _withCtx(() => [
          _createElementVNode("rbe-trial-actions", null, [
            _createElementVNode("button", {
              class: "button",
              type: "button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, "\n          Not yet\n        "),
            _cache[4] || (_cache[4] = _createTextVNode()),
            _createVNode(_component_LoadingButton, {
              class: "button",
              type: "button",
              loading: _ctx.loading,
              onClick: _ctx.startTrial
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("\n          Start free trial\n        ")
              ])),
              _: 1
            }, 8, ["loading", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["id", "title"]))
}