
import {Component, Inject, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import AddOnToggle from './add-on-toggle.vue';
import {config} from '@reedsy/studio.shared/config';
import SubscriptionStartedBase from './subscription-started-base.vue';

@Component({
  components: {
    AddOnToggle,
    SubscriptionStartedBase,
  },
})
export default class SubscriptionTrialStarted extends mixins(ModalMixin, ClientSharedVue) {
  @Inject
  public tourBookUuid: string;

  public readonly config = config;
  public readonly cancelable = true;

  public get description(): string {
    if (this.tourBookUuid) {
      return 'Discover all our features or jump straight into writing.';
    }

    return 'Create a book and jump straight into writing.';
  }
}
