import Delta from '@reedsy/quill-delta';
import listTrackedChanges from '@reedsy/studio.shared/store/modules/rich-text/list-tracked-changes';
import acceptAll from '@reedsy/studio.shared/services/track-changes/change-applier/accept-all';
import resolveComments from '@reedsy/studio.shared/services/track-changes/change-applier/resolve-comments';
import {combine} from '@reedsy/utils.ot-rich-text';
import {splitOpsOnNewlines} from '@reedsy/utils.ot-rich-text';
import ReedsyClipboard from '@reedsy/studio.shared/services/quill/modules/clipboard';
import {ClipboardSource} from '@reedsy/studio.isomorphic/services/quill/modules/clipboard/clipboard-source';
import findQuill from '@reedsy/studio.shared/services/quill/helpers/find-quill';
import {ClipboardMatcher} from './i-clipboard-matcher';

export const trackedChangeMatcher: ClipboardMatcher = function trackedChangeMatcher(_node, delta, scroll) {
  const quill = findQuill(scroll.domNode);

  const clipboard = quill.getModule('clipboard') as ReedsyClipboard;
  const pasteSource = clipboard.pasteSource;

  delta = new Delta(splitOpsOnNewlines(delta.ops));

  const changes = listTrackedChanges(delta.ops);

  // The change appliers need a base document to check ops against.
  // The paste can be considered a standalone document on its own,
  // so let's just use the delta itself as the base document.
  const contents = delta.ops;
  const accept = acceptAll(changes, contents);
  // Leave comments in tact when pasting within the same book. Otherwise
  // strip them, since comment threads are book-scoped
  const resolve = pasteSource === ClipboardSource.SameBook ?
    null :
    resolveComments(changes, contents);
  return delta.compose(combine(accept, resolve));
};
