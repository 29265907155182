<template>
  <div
    id="top-bar"
    class="top-bar"
  >
    <transition name="slide">
      <div
        v-if="alertBarIsOpen"
        class="alert-bar"
      >
        <p>Access to this preview expires at {{ displayedExpiryDate }}</p>
      </div>
    </transition>
    <div class="top-bar-controls">
      <div class="left-side">
        <OptionButton
          class="toggle-left-sidebar-button button"
          @click="toggleLeftSidebar"
        >
          <VuiIconMenu class="contents-button-icon" />
          <span class="contents-button-text hidden-until-md">Contents</span>
        </OptionButton>
      </div>

      <div class="middle ellipsis">
        <p class="book-title ellipsis">
          {{ bookTitle }}
        </p>
      </div>

      <div class="right-side">
        <OptionButton
          ref="toggleDarkModeButton"
          v-tooltip="tooltipOptions('Color scheme')"
          class="toggle-dark-mode-button button-icon squared"
          @click="toggleDarkMode"
        >
          <transition
            name="toggle-dark-mode"
            mode="out-in"
          >
            <MoonIcon
              v-if="isDarkMode"
              class="dark-mode-icon"
            />
            <SunIcon
              v-else
              class="light-mode-icon"
            />
          </transition>
        </OptionButton>
        <DrawerPopover
          :open="fontSettingsMenuIsOpen"
          title="Font settings"
          @hide="hideFontSettingsMenu"
        >
          <template #trigger>
            <OptionButton
              v-tooltip="tooltipOptions('Font settings')"
              class="toggle-font-settings-menu-button button-icon squared"
              :class="{active: fontSettingsMenuIsOpen}"
              @click="toggleFontSettingsMenu"
            >
              <FontIcon class="font-settings-menu-icon" />
            </OptionButton>
          </template>
          <template #menu-content>
            <FontSettingsMenu />
          </template>
        </DrawerPopover>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import SunIcon from '@reedsy/studio.shared/components/icons/icon-sun.vue';
import MoonIcon from '@reedsy/studio.shared/components/icons/icon-moon.vue';
import FontIcon from '@reedsy/studio.shared/components/icons/icon-font.vue';
import OptionButton from './option-button.vue';
import DrawerPopover from '@reedsy/studio.viewer/components/drawer-popover/drawer-popover.vue';
import FontSettingsMenu from '@reedsy/studio.viewer/components/font-settings-menu/font-settings-menu.vue';
import time from '@reedsy/studio.shared/filters/time';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerLayoutModule} from '@reedsy/studio.viewer/store/modules/layout/book-viewer-layout';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';
import {BookViewerShareableUrlDetailsModule} from '@reedsy/studio.viewer/store/modules/shareable-url-details/shareable-url-details';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {AppTheme} from '@reedsy/studio.shared/store/modules/user-settings/app-theme';

@Component({
  components: {
    SunIcon,
    MoonIcon,
    FontIcon,
    OptionButton,
    FontSettingsMenu,
    DrawerPopover,
  },
})
export default class TopBar extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerLayout)
  public $layout: BookViewerLayoutModule;

  @$lazyInjectStore(StoreName.BookViewerSettings)
  public $settings: BookViewerSettingsModule;

  @$lazyInjectStore(StoreName.BookViewerSharableUrlDetails)
  public $shareableUrlDetails: BookViewerShareableUrlDetailsModule;

  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  public get isDarkMode(): boolean {
    return this.$settings.appTheme === AppTheme.Dark;
  }

  public get fontSettingsMenuIsOpen(): boolean {
    return this.$layout.fontSettingsMenuIsOpen;
  }

  public get alertBarIsOpen(): boolean {
    return this.$shareableUrlDetails.isSoonToBeExpired;
  }

  public get sharableUrlExpiryDate(): Date {
    return this.$shareableUrlDetails.shareableUrlDetails.expiryDate;
  }

  public get displayedExpiryDate(): string {
    return time(this.sharableUrlExpiryDate);
  }

  public get bookTitle(): string {
    return this.$bookContent.bookTitle;
  }

  public toggleLeftSidebar(): void {
    this.$layout.toggleLeftSidebar();
  }

  public toggleDarkMode(): void {
    this.$settings.toggleDarkMode();
  }

  public async toggleFontSettingsMenu(): Promise<void> {
    this.$layout.toggleFontSettingsMenu();
  }

  public async hideFontSettingsMenu(): Promise<void> {
    this.$layout.hideFontSettingsMenu();
  }

  public tooltipOptions(content: string): any {
    return {
      content,
      distance: 15,
    };
  }
}
</script>

<style lang="scss" scoped>
.orientation-landscape-right .top-bar {
  & .alert-bar, & .top-bar-controls {
    padding-right: calc($space-sm + env(safe-area-inset-right));

    @include screen-more-than(sm) {
      padding-right: calc($space-lg + env(safe-area-inset-right));
    }
  }
}

.orientation-landscape-left .top-bar {
  & .alert-bar, & .top-bar-controls {
    padding-left: calc($space-sm + env(safe-area-inset-left));

    @include screen-more-than(sm) {
      padding-left: calc($space-lg + env(safe-area-inset-left));
    }
  }
}

.top-bar {
  --mobile-menu-height: #{$short-mobile-menu-height};

  width: 100%;
  border-bottom: $border;
  position: fixed;
  top: 0;
  background-color: var(--reedsy-plain);

  @include transition(background-color);

  .alert-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $book-viewer-alert-bar-height;
    background-color: var(--studio-callout-backgroundColor);
    color: var(--studio-callout-textColor);
    border-bottom: $border;

    @include font-family($controls, bold);
  }

  .top-bar-controls {
    height: $book-viewer-top-bar-height;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 $space-sm;

    @include screen-more-than(sm) {
      padding: 0 $space-lg 0 $space-md;
    }

    .contents-button-text {
      margin-left: $space-sm;

      @include font-family($controls, regular);
    }

    .left-side, .middle, .right-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }

    .left-side {
      padding-left: 0;
      justify-content: flex-start;

      @include screen-more-than(md) {
        flex: 1;
      }
    }

    .middle {
      justify-content: center;
      padding: 0 $space-md;

      .book-title {
        @include font-family($controls, regular);

        @include screen-more-than(sm) {
          padding-left: $space-md;
        }
      }
    }

    .right-side {
      flex: 1;
      justify-content: flex-end;

      @include screen-more-than(sm) {
        & > * {
          margin-left: $space-sm;
        }
      }
    }

    .toggle-dark-mode-button :deep(.vui-icon) {
      &.toggle-dark-mode-enter-active, &.toggle-dark-mode-leave-active {
        transition: opacity #{0.5 * $vui-transition-time-fast ease};
      }

      &.toggle-dark-mode-enter-from, &.toggle-dark-mode-leave-to {
        opacity: 0;
      }
    }
  }
}
</style>
