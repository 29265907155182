<template>
  <section
    class="feature-summaries"
    :class="{
      'mobile-version': showMobileVersion,
      'desktop-version': !showMobileVersion,
    }"
  >
    <swiper-container
      v-if="showMobileVersion"
      ref="swiper"
      grab-cursor="true"
      centered-slides="true"
      slides-per-view="1.2"
      space-between="10"
      events-prefix="swiper-"
      slide-to-clicked-slide
    >
      <swiper-slide
        v-for="summary in summaries"
        :key="summary.product"
        class="flex-centered"
      >
        <FeatureSummary
          :ref="summary.product"
          v-model="selectedProducts[summary.product]"
          :summary="summary"
          :interval="interval"
          :prices="prices"
          :is-preview="featurePreview"
        />
      </swiper-slide>
    </swiper-container>
    <template v-else>
      <FeatureSummary
        v-for="summary in summaries"
        :key="summary.product"
        :ref="summary.product"
        v-model="selectedProducts[summary.product]"
        :summary="summary"
        :interval="interval"
        :prices="prices"
        :is-preview="featurePreview"
      />
    </template>
  </section>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';
import {IPriceOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {PropType} from 'vue';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@Component({
  components: {
    FeatureSummary,
  },
})
export default class FeatureSummaries extends ClientSharedVue {
  @Model({type: Object, default: {}})
  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>>;

  @Prop({type: Boolean, default: false})
  public featurePreview: boolean;

  @Prop({type: Object as PropType<IPriceOptionsResponse>})
  public prices: IPriceOptionsResponse;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  @$lazyInjectStore(SharedStoreName.Browser)
  public readonly $browser: SharedBrowserModule;

  public get summaries(): ReadonlyArray<IProductSummary> {
    if (!this.featurePreview) return FEATURE_SUMMARIES;

    return FEATURE_SUMMARIES.filter((summary) => summary.product);
  }

  public get showMobileVersion(): boolean {
    return this.$browser.screen.lessThan.md;
  }
}
</script>

<style lang="scss" scoped>
.feature-summaries {
  padding: $space-md 0;

  &.desktop-version {
    display: flex;
    flex-wrap: wrap;
    gap: $space-base;
    align-items: stretch;

    & > * {
      flex: 1 0 0;
    }
  }
}

swiper-slide {
  display: flex;
  justify-items: center;
  height: auto;

  .feature-summary {
    height: 100%;
    width: 100%;
  }
}
</style>
