<template>
  <SkeletonLoader
    v-if="loading"
    class="price-loader"
  />
  <span v-else>
    <span class="amount">{{ amount }}</span><span class="interval">{{ intervalDisplay }}</span>
  </span>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IBillingInterval, SupportedCurrency} from '@reedsy/schemas.editor-collections';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

const INTERVAL_SEPARATOR: string = '/';
const INTERVAL_DISPLAY: Record<IBillingInterval, string> = {
  month: 'mo',
  year: 'yr',
};

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class SubscriptionPrice extends ClientSharedVue {
  @Prop({type: Number})
  public price: number;

  @Prop({type: String as PropType<SupportedCurrency>})
  public currency: SupportedCurrency;

  @Prop({type: String as PropType<IBillingInterval>})
  public interval: IBillingInterval;

  public get loading(): boolean {
    return !(this.price !== undefined && this.currency && this.interval);
  }

  public get amount(): string {
    return this.price.toLocaleString('en-US', {
      style: 'currency',
      currency: this.currency,
    });
  }

  public get intervalDisplay(): string {
    return INTERVAL_SEPARATOR + INTERVAL_DISPLAY[this.interval];
  }
}
</script>

<style lang="scss" scoped>
.price-loader {
  height: 1em;
}
</style>
