<template>
  <PlainModal
    :id="id"
    :headless="true"
    class="subscription-started-modal accent-premium extra-wide no-padding"
  >
    <rbe-subscription-started>
      <slot name="banner" />

      <h1>
        {{ title }}
      </h1>

      <p>
        <slot />
      </p>

      <template v-if="tourBookUuid">
        <button
          ref="startTour"
          class="button reedsy-accented"
          type="button"
          @click="startTour"
        >
          Take a tour
        </button>

        <button
          class="button-subtle"
          type="button"
          @click="close"
        >
          Skip tour
        </button>
      </template>
      <template v-else>
        <button
          class="button reedsy-accented"
          type="button"
          @click="close"
        >
          Let’s go!
        </button>
      </template>
    </rbe-subscription-started>
  </PlainModal>
</template>

<script lang="ts">
import {Component, Inject, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {EditorTourName} from '@reedsy/studio.shared/services/spotlight-tour/tour-names';
import {ISpotlightTour} from '@reedsy/studio.shared/services/spotlight-tour/i-spotlight-tour';

@Component({
  components: {
    PlainModal,
  },
})
export default class SubscriptionStartedBase extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('SpotlightTour')
  public $tour: ISpotlightTour;

  @Inject
  public tourBookUuid: string;

  @Prop({type: String, required: true})
  public title: string;

  public readonly cancelable = true;

  public startTour(): void {
    this.close();
    // TODO: [subscriptions] subscription-specific tour?
    this.$tour.start(EditorTourName.PlanFeatures, {bookUuid: this.tourBookUuid});
  }
}
</script>

<style lang="scss" scoped>
.subscription-started-modal {
  rbe-subscription-started {
    padding: 2rem;
    width: 37rem;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-sm;
    margin: auto;
  }

  h1 {
    font-size: $font-size-xxl;
    text-align: center;
  }

  p {
    margin-bottom: $space-base;
  }
}
</style>
