import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "price-headline" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!

  return (_openBlock(), _createElementBlock("rbe-price-info", {
    class: _normalizeClass(["flex-top", {compact: _ctx.compact}])
  }, [
    _createElementVNode("rbe-price-item", null, [
      _cache[0] || (_cache[0] = _createElementVNode("span", null, "Subtotal", -1)),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createVNode(_component_SubscriptionPrice, {
        price: _ctx.subtotal,
        currency: _ctx.currency,
        interval: _ctx.interval
      }, null, 8, ["price", "currency", "interval"])
    ]),
    _cache[7] || (_cache[7] = _createTextVNode()),
    _createElementVNode("rbe-price-item", null, [
      _cache[2] || (_cache[2] = _createElementVNode("span", null, "Discounts", -1)),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createVNode(_component_SubscriptionPrice, {
        price: _ctx.discount,
        currency: _ctx.currency,
        interval: _ctx.interval
      }, null, 8, ["price", "currency", "interval"])
    ]),
    _cache[8] || (_cache[8] = _createTextVNode()),
    _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
    _cache[10] || (_cache[10] = _createTextVNode()),
    _createElementVNode("rbe-price-item", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.compact ? 'Total' : 'Your subscription'), 1),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createVNode(_component_SubscriptionPrice, {
        price: _ctx.total,
        currency: _ctx.currency,
        interval: _ctx.interval
      }, null, 8, ["price", "currency", "interval"])
    ]),
    _cache[11] || (_cache[11] = _createTextVNode()),
    (_ctx.nextBillingDate)
      ? (_openBlock(), _createElementBlock("rbe-price-item", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("span", null, "Next billing date", -1)),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createElementVNode("span", null, _toDisplayString(_ctx.nextBillingDate), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}