import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex-right" }
const _hoisted_3 = {
  key: 0,
  class: "total-price flex-justified"
}
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeriodToggle = _resolveComponent("PeriodToggle")!
  const _component_FeatureSummaries = _resolveComponent("FeatureSummaries")!
  const _component_MultipleSaving = _resolveComponent("MultipleSaving")!
  const _component_SubscriptionPrice = _resolveComponent("SubscriptionPrice")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
    id: _ctx.id,
    title: _ctx.title,
    subtitle: "Unlock Studio with powerful add-ons.",
    class: "subscription-features-modal selector-modal"
  }, {
    "header-append": _withCtx(() => [
      _createVNode(_component_PeriodToggle, {
        modelValue: _ctx.billAnnually,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billAnnually) = $event))
      }, null, 8, ["modelValue"])
    ]),
    "footer-prepend": _withCtx(() => [
      (_ctx.hasMultipleOfferings)
        ? (_openBlock(), _createElementBlock("rbe-savings-notice", _hoisted_1, [
            _createVNode(_component_MultipleSaving),
            _cache[3] || (_cache[3] = _createTextVNode("\n        when you purchase multiple add-ons\n      "))
          ]))
        : _createCommentVNode("", true)
    ]),
    action: _withCtx(() => [
      _createElementVNode("rbe-payment-section", _hoisted_2, [
        (_ctx.hasSelectedAnything)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[5] || (_cache[5] = _createElementVNode("span", null, "Total", -1)),
              _cache[6] || (_cache[6] = _createTextVNode()),
              _createElementVNode("rbe-price-display", null, [
                (_ctx.discount)
                  ? (_openBlock(), _createBlock(_component_SubscriptionPrice, {
                      key: 0,
                      class: "before-discount",
                      price: _ctx.calculatedPrice?.totalBeforeDiscounts,
                      currency: _ctx.calculatedPrice?.currency,
                      interval: _ctx.calculatedPrice?.interval
                    }, null, 8, ["price", "currency", "interval"]))
                  : _createCommentVNode("", true),
                _cache[4] || (_cache[4] = _createTextVNode()),
                _createVNode(_component_SubscriptionPrice, {
                  price: _ctx.calculatedPrice?.total,
                  currency: _ctx.calculatedPrice?.currency,
                  interval: _ctx.calculatedPrice?.interval
                }, null, 8, ["price", "currency", "interval"])
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[7] || (_cache[7] = _createTextVNode()),
        _createElementVNode("button", {
          ref: "continue",
          class: "button reedsy-accented continue-button",
          type: "button",
          disabled: !_ctx.calculatedPrice,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.continueToPayment && _ctx.continueToPayment(...args)))
        }, "\n          Continue\n        ", 8, _hoisted_4)
      ])
    ]),
    default: _withCtx(() => [
      _cache[8] || (_cache[8] = _createTextVNode()),
      _createVNode(_component_FeatureSummaries, {
        modelValue: _ctx.selectedProducts,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedProducts) = $event)),
        prices: _ctx.prices,
        interval: _ctx.interval
      }, null, 8, ["modelValue", "prices", "interval"]),
      _cache[9] || (_cache[9] = _createTextVNode()),
      _cache[10] || (_cache[10] = _createTextVNode())
    ]),
    _: 1
  }, 8, ["id", "title"]))
}