import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "swiper",
  "grab-cursor": "true",
  "centered-slides": "true",
  "slides-per-view": "1.2",
  "space-between": "10",
  "events-prefix": "swiper-",
  "slide-to-clicked-slide": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureSummary = _resolveComponent("FeatureSummary")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["feature-summaries", {
      'mobile-version': _ctx.showMobileVersion,
      'desktop-version': !_ctx.showMobileVersion,
    }])
  }, [
    (_ctx.showMobileVersion)
      ? (_openBlock(), _createElementBlock("swiper-container", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries, (summary) => {
            return (_openBlock(), _createElementBlock("swiper-slide", {
              key: summary.product,
              class: "flex-centered"
            }, [
              _createVNode(_component_FeatureSummary, {
                ref_for: true,
                ref: summary.product,
                modelValue: _ctx.selectedProducts[summary.product],
                "onUpdate:modelValue": ($event: any) => ((_ctx.selectedProducts[summary.product]) = $event),
                summary: summary,
                interval: _ctx.interval,
                prices: _ctx.prices,
                "is-preview": _ctx.featurePreview
              }, null, 8, ["modelValue", "onUpdate:modelValue", "summary", "interval", "prices", "is-preview"])
            ]))
          }), 128))
        ], 512))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.summaries, (summary) => {
          return (_openBlock(), _createBlock(_component_FeatureSummary, {
            key: summary.product,
            ref_for: true,
            ref: summary.product,
            modelValue: _ctx.selectedProducts[summary.product],
            "onUpdate:modelValue": ($event: any) => ((_ctx.selectedProducts[summary.product]) = $event),
            summary: summary,
            interval: _ctx.interval,
            prices: _ctx.prices,
            "is-preview": _ctx.featurePreview
          }, null, 8, ["modelValue", "onUpdate:modelValue", "summary", "interval", "prices", "is-preview"]))
        }), 128))
  ], 2))
}