export interface IStyleOptions {
  getFocusStylesByStealingFocus?: boolean;
}

export function getAppliedStyles(
  tag: keyof HTMLElementTagNameMap,
  attributes: Record<string, string> = {},
  options: IStyleOptions = {},
): Partial<CSSStyleDeclaration> {
  const styleAttribute = attributes.style || '';

  const styles = _getAppliedStyles(
    tag,
    {
      ...attributes,
      // Disable transition to get target styles after transition
      style: [styleAttribute, 'transition: none'].join(';'),
    },
    options,
  );
  const stylesWithTransition = _getAppliedStyles(tag, attributes, options);
  const defaultStyles = getDefaultStyles(tag, attributes, options);

  const rules: Partial<CSSStyleDeclaration> = {};
  for (const key in styles) {
    let value = styles[key];
    if (key === 'transition') value = stylesWithTransition[key];
    if (value !== defaultStyles[key]) rules[key] = value;
  }

  return rules;
}

function getDefaultStyles(
  tag: keyof HTMLElementTagNameMap,
  attributes: Record<string, string>,
  options: IStyleOptions,
): Partial<CSSStyleDeclaration> {
  const iframe = document.createElement('iframe');
  document.body.append(iframe);
  attributes = {
    ...attributes,
    style: '', // Scrub inline styling to get defaults
  };
  const styles = _getAppliedStyles(tag, attributes, options, iframe.contentWindow);
  iframe.remove();
  return styles;
}

function _getAppliedStyles(
  tag: keyof HTMLElementTagNameMap,
  attributes: Record<string, string>,
  options: IStyleOptions,
  $window: Window = window,
): Partial<CSSStyleDeclaration> {
  const rules: Record<string, string> = {};
  const {getFocusStylesByStealingFocus} = options;
  const element = $window.document.createElement(tag);
  for (const key in attributes) element.setAttribute(key, attributes[key]);

  $window.document.body.appendChild(element);
  if (getFocusStylesByStealingFocus) element.focus();
  const styles = $window.getComputedStyle(element);

  for (const key in styles) {
    if (Number.isInteger(+key)) continue;
    if (typeof styles[key] === 'string') {
      rules[key] = styles[key];
    }
  }

  element.remove();
  return rules;
}
