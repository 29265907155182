<template>
  <SubscriptionStartedBase
    :id="id"
    :title="title"
  >
    <template #banner>
      <!-- TODO: [subscriptions] replace placeholder image -->
      <img
        :src="image"
        alt="Studio Pro add-on"
      >
      TODO: [subscriptions] Replace placeholder illustration
    </template>

    <span
      v-if="tourBookUuid"
      ref="description"
    >
      Take a quick tour to refresh yourself with the features that come with the {{ selectedAddOnsText }},
      or jump straight back into writing.
    </span>
  </SubscriptionStartedBase>
</template>

<script lang="ts">
import {Component, Inject, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionStartedBase from './subscription-started-base.vue';
import image from '@reedsy/studio.assets/beta-modal-image.png';
import {PropType} from 'vue';
import {ISubscriptionPaymentSuccessModalArgs} from './subscription-payment-success-modal-args.interface';
import {FEATURE_SUMMARIES} from './features/feature-summaries';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {first} from '@reedsy/utils.iterable';

@Component({
  components: {
    SubscriptionStartedBase,
  },
})
export default class SubscriptionPaymentSuccess extends mixins(ModalMixin, ClientSharedVue) {
  @Inject
  public tourBookUuid: string;

  @Prop({type: Object as PropType<ISubscriptionPaymentSuccessModalArgs>, default: {}})
  public context: ISubscriptionPaymentSuccessModalArgs;

  public readonly image = image;
  public readonly cancelable = true;

  public get selectedAddOnsText(): string {
    if (this.context.products.size > 1) return 'selected add-ons';

    return `${this.addOnTitle(first(this.context.products))} add-on`;
  }

  public get title(): string {
    if (this.context.products.size > 1) return 'Add-ons enabled!';

    return `${this.addOnTitle(first(this.context.products))} add-on enabled!`;
  }

  public addOnTitle(product: SubscriptionProduct): string {
    return FEATURE_SUMMARIES.find((summary) => summary.product === product).title;
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  max-width: 20rem;
  height: auto;
}
</style>
