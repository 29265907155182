<template>
  <div
    ref="wrapper"
    class="content-pane scrollbar-alt"
  >
    <slot name="header">
      <Header
        ref="header"
        :title="title"
      >
        <slot name="title" />
        <slot name="header-after-content" />
      </Header>
    </slot>

    <div class="content">
      <slot name="content" />
    </div>

    <Footer>
      <slot name="footer" />
    </Footer>
  </div>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import Footer from './footer/footer.vue';
import Header from './header/header.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class ContentPane extends ClientSharedVue {
  @Prop({type: String})
  public title: string;
}
</script>

<style lang="scss" scoped>
$left-sidebar-header-height: 3rem;
$left-sidebar-content-height-gutter: $left-sidebar-header-height + $left-sidebar-footer-height;

.content-pane {
  @include font-family($controls, bold);

  background-color: var(--studio-leftSidebar-background);
  height: safe-vh(100);
  overflow: hidden auto;
  width: $left-sidebar-content-width;
  container-type: size;
  border-right: $left-sidebar-separator;
  box-sizing: border-box;

  // Only needed because Chrome 127 has a bug that hides the sidebar when the board switcher is expanded
  z-index: 0;

  .document-history-wrapper.active & {
    height: calc(safe-vh(100) - $document-history-content-height-gutter);
  }

  .content {
    $left-sidebar-header-height: 3rem;

    min-height: calc(safe-vh(100) - var(--left-sidebar-footer-height) - $left-sidebar-header-height);
    box-sizing: border-box;
    border-top: $left-sidebar-separator;

    .document-history-wrapper.active & {
      @include transition(min-height);

      @include screen-more-than(xs) {
        $gutter: $left-sidebar-header-height + $document-history-content-height-gutter;

        min-height: calc(safe-vh(100) - var(--left-sidebar-footer-height) - $gutter);
      }
    }
  }
}
</style>
