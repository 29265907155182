import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "amount" }
const _hoisted_3 = { class: "interval" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_SkeletonLoader, {
        key: 0,
        class: "price-loader"
      }))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.amount), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.intervalDisplay), 1)
      ]))
}