import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "trigger-wrapper" }
const _hoisted_2 = { ref: "menu-content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _component_MobileDrawer = _resolveComponent("MobileDrawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VDropdown, {
      ref: "popover-component",
      shown: _ctx.isPopoverOpen,
      placement: "bottom-end",
      "popper-class": "v-popper--no-arrow v-popper--drawer",
      distance: 15,
      triggers: []
    }, {
      popper: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "menu-content")
        ], 512)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "trigger")
        ], 512),
        _cache[0] || (_cache[0] = _createTextVNode())
      ]),
      _: 3
    }, 8, ["shown"]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createVNode(_component_MobileDrawer, {
      show: _ctx.isMobileDrawerOpen,
      onHide: _ctx.hide
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createTextVNode()),
        _renderSlot(_ctx.$slots, "menu-content")
      ]),
      _: 3
    }, 8, ["show", "onHide"])
  ]))
}