/* eslint-disable @stylistic/max-len */
export const SHARED_MODAL_COMPONENT_MAP = {
  Desynchronisation: () => import('./components/desynchronisation-modal.vue'),
  Offline: () => import('./components/offline-modal.vue'),
  SubscriptionFeatures: () => import('@reedsy/studio.shared/components/modals/components/subscription/features/subscription-features.vue'),
  SubscriptionPayment: () => import('@reedsy/studio.shared/components/modals/components/subscription/features/subscription-payment.vue'),
  SubscriptionTrial: () => import('@reedsy/studio.shared/components/modals/components/subscription/subscription-trial.vue'),
  SubscriptionTrialStarted: () => import('@reedsy/studio.shared/components/modals/components/subscription/subscription-trial-started.vue'),
  SubscriptionPaymentSuccess: () => import('@reedsy/studio.shared/components/modals/components/subscription/subscription-payment-success.vue'),
  UpdateAvailable: () => import('./components/update-available-modal.vue'),
  ConfirmResendInvitation: () => import('./components/confirm-resend-invitation-modal.vue'),
  InviteCollaborator: () => import('./components/invite-collaborator.vue'),
  ConfirmRevokeAccess: () => import('./components/confirm-revoke-access-modal.vue'),
  ConfirmRevokeInvitation: () => import('./components/confirm-revoke-invitation-modal.vue'),
  ConfirmLeaveBook: () => import('./components/confirm-leave-book-modal.vue'),
  FeedbackSurvey: () => import('./components/feedback/feedback-survey.vue'),
  ConfirmCancelSubscription: () => import('./components/subscription/confirm-cancel-subscription-modal.vue'),
} as const;

export type SharedModalType = keyof typeof SHARED_MODAL_COMPONENT_MAP;
