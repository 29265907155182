
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {ScreenSize} from '@reedsy/studio.shared/store/modules/browser/screen-size';
import MobileDrawer from '@reedsy/studio.shared/components/mobile-drawer/mobile-drawer.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';
import refEl from '@reedsy/studio.shared/utils/vue/ref-el';

@Component({
  components: {
    MobileDrawer,
  },
})
export default class DrawerPopover extends BookViewerVue {
  @$lazyInjectStore(SharedStoreName.Browser)
  public $browser: SharedBrowserModule;

  @Prop({type: String, default: ''})
  public title: string;

  @Prop({type: Boolean, default: false})
  public open: boolean;

  public get greaterThanOrEqualSm(): boolean {
    return this.$browser.screen.greaterThanOrEqual[ScreenSize.Small];
  }

  public get isPopoverOpen(): boolean {
    return this.open && this.greaterThanOrEqualSm;
  }

  public get isMobileDrawerOpen(): boolean {
    return this.open && !this.greaterThanOrEqualSm;
  }

  @Emit('hide')
  public hide(): void {}

  public mounted(): void {
    window.addEventListener('click', this.emitCloseOnClickOutside);
  }

  public beforeUnmount(): void {
    window.removeEventListener('click', this.emitCloseOnClickOutside);
  }

  public emitCloseOnClickOutside(event: PointerEvent): void {
    const path = event.composedPath();

    if (!this.isPopoverOpen) {
      return;
    }

    if (
      path.includes(refEl(this.$refs['menu-content-wrapper'])) ||
      path.includes(refEl(this.$refs['trigger-wrapper']))
    ) {
      return;
    }

    this.hide();
  }
}
