<template>
  <div
    class="book-viewer"
  >
    <div
      v-if="!isNotFoundView && isLayoutLoading"
      class="initial-book-viewer-loader-wrapper"
    >
      <VuiLoadingIndicator />
    </div>
    <RouterView
      v-else-if="!isSwitchingContent"
      class="book-viewer-page"
    />
  </div>
</template>

<script lang="ts">
import BookViewerVue from './book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {BookViewerRouteName} from '@reedsy/studio.shared/router/route-names/book-viewer-route-name';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from './store/store-name';
import {BookViewerDisplayedDataModule} from './store/modules/displayed-data/book-viewer-displayed-data';
import {BookViewerLayoutModule} from './store/modules/layout/book-viewer-layout';

@Component({})
export default class BookViewerApp extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerLayout)
  public $layout: BookViewerLayoutModule;

  public get isNotFoundView(): boolean {
    return this.$route.name === BookViewerRouteName.NotFound;
  }

  public get isSwitchingContent(): boolean {
    return this.$displayedData.isSwitchingContent;
  }

  public get isLayoutLoading(): boolean {
    return this.$layout.loading;
  }
}
</script>

<style lang="scss">
@use '@reedsy/studio.shared/style/main';
@use '@reedsy/studio.viewer/style/book-viewer';
</style>

<style lang="scss" scoped>

.book-viewer {
  background-color: var(--reedsy-plain);
  min-height: safe-vh(100);

  @include transition(background-color);

  .initial-book-viewer-loader-wrapper {
    display: flex;
    justify-content: center;
    padding-top: var(--initial-loader-padding-top);
  }
}
</style>
