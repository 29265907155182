
import {Component, Prop, Ref, Emit} from '@reedsy/studio.shared/utils/vue/decorators';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    Overlay,
  },
})
export default class MobileDrawer extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public show: boolean;

  @Prop({type: Boolean, default: false})
  public withOverlay: boolean;

  @Ref('container')
  private container: HTMLDivElement;

  private readonly DRAG_CLOSE_THRESHOLD_PX = 100;
  private initY: number = null;
  private initHeight: number = null;

  public closeBySwipe(): void {
    this.hide();
  }

  @Emit('hide')
  public hide(): boolean {
    return true;
  }

  public touchStart(e: TouchEvent): void {
    if (e.touches.length !== 1) return;

    this.initY = e.touches[0].pageY;
    this.initHeight = this.initHeight || this.container.getBoundingClientRect().height;
    this.container.classList.remove('transition');
  }

  public resizeOnMove(e: TouchEvent): void {
    if (e.touches.length !== 1) return;

    const diff = e.touches[0].pageY - this.initY;

    if (diff > this.DRAG_CLOSE_THRESHOLD_PX) {
      this.closeBySwipe();
      return;
    }

    this.container.style.height = `${this.initHeight - diff}px`;
  }

  public touchEnd(): void {
    this.container.classList.add('transition');
    this.container.style.height = `${this.initHeight}px`;
  }
}
