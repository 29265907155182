import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionStartedBase = _resolveComponent("SubscriptionStartedBase")!

  return (_openBlock(), _createBlock(_component_SubscriptionStartedBase, {
    id: _ctx.id,
    title: _ctx.title
  }, {
    banner: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.image,
        alt: "Studio Pro add-on"
      }, null, 8, _hoisted_1),
      _cache[0] || (_cache[0] = _createTextVNode("\n      TODO: [subscriptions] Replace placeholder illustration\n    "))
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createTextVNode()),
      (_ctx.tourBookUuid)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            ref: "description"
          }, "\n      Take a quick tour to refresh yourself with the features that come with the " + _toDisplayString(_ctx.selectedAddOnsText) + ",\n      or jump straight back into writing.\n    ", 513))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}