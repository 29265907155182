import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddOnToggle = _resolveComponent("AddOnToggle")!
  const _component_SubscriptionStartedBase = _resolveComponent("SubscriptionStartedBase")!

  return (_openBlock(), _createBlock(_component_SubscriptionStartedBase, {
    id: _ctx.id,
    title: `Your ${_ctx.config.stripe.trialDays} day trial has started`
  }, {
    banner: _withCtx(() => [
      _createElementVNode("rbe-trial-started", null, [
        _createElementVNode("rbe-toggles", null, [
          _createVNode(_component_AddOnToggle, {
            title: "Studio Pro",
            "model-value": true,
            disabled: true
          }),
          _cache[0] || (_cache[0] = _createTextVNode()),
          _createVNode(_component_AddOnToggle, {
            title: "Outlining",
            "model-value": true,
            disabled: true
          })
        ])
      ])
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("span", { ref: "description" }, "\n      You’re now on our premium plan. " + _toDisplayString(_ctx.description), 513)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}