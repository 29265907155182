import {IPriceOption} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-options-response';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';

export function choosePrice(
  currency: SupportedCurrency,
  options: ReadonlyArray<IPriceOption>,
): IPriceOption {
  let fallback: IPriceOption;
  for (const option of options) {
    if (!fallback || option.currency === 'usd') fallback = option;
    if (option.currency === currency) return option;
  }
  return fallback;
}
