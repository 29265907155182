<template>
  <div class="mobile-drawer">
    <transition name="fade">
      <Overlay
        v-if="withOverlay && show"
        v-touch:swipe.bottom="closeBySwipe"
        @click="hide"
      />
    </transition>
    <transition name="move-top">
      <div
        v-if="show"
        ref="container"
        class="container"
      >
        <div
          v-touch:press.stop="touchStart"
          v-touch:drag.stop="resizeOnMove"
          v-touch:release.stop="touchEnd"
          class="header"
        >
          <slot name="header" />
          <button
            type="button"
            class="close-drawer-button button-sm button-icon reedsy-accented translucent"
            @click="hide"
          >
            <VuiIconCross />
          </button>
        </div>
        <div class="content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, Emit} from '@reedsy/studio.shared/utils/vue/decorators';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    Overlay,
  },
})
export default class MobileDrawer extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public show: boolean;

  @Prop({type: Boolean, default: false})
  public withOverlay: boolean;

  @Ref('container')
  private container: HTMLDivElement;

  private readonly DRAG_CLOSE_THRESHOLD_PX = 100;
  private initY: number = null;
  private initHeight: number = null;

  public closeBySwipe(): void {
    this.hide();
  }

  @Emit('hide')
  public hide(): boolean {
    return true;
  }

  public touchStart(e: TouchEvent): void {
    if (e.touches.length !== 1) return;

    this.initY = e.touches[0].pageY;
    this.initHeight = this.initHeight || this.container.getBoundingClientRect().height;
    this.container.classList.remove('transition');
  }

  public resizeOnMove(e: TouchEvent): void {
    if (e.touches.length !== 1) return;

    const diff = e.touches[0].pageY - this.initY;

    if (diff > this.DRAG_CLOSE_THRESHOLD_PX) {
      this.closeBySwipe();
      return;
    }

    this.container.style.height = `${this.initHeight - diff}px`;
  }

  public touchEnd(): void {
    this.container.classList.add('transition');
    this.container.style.height = `${this.initHeight}px`;
  }
}
</script>

<style lang="scss" scoped>
$header-height: 3rem;

.mobile-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-index-mobile-menu;

  .container {
    background-color: var(--reedsy-plain);
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    display: flex;
    flex-direction: column;
    height: var(--mobile-menu-height);
    max-height: $mobile-menu-height;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:not(:has(.overlay)) {
      box-shadow: $box-shadow-up-base;
    }

    &.transition {
      @include transition(height);
    }

    .content {
      overflow-x: hidden;
    }
  }

  .header {
    @include font-family($controls, bold);

    box-sizing: border-box;
    align-items: center;
    border-bottom: $vui-border-width-sm solid var(--reedsy-neutral);
    display: flex;
    flex-shrink: 0;
    padding: $space-sm $space-md;
    position: relative;
    height: $header-height;
  }

  .close-drawer-button {
    flex-shrink: 0;
    margin-left: auto;
  }

  .content {
    padding-bottom: env(safe-area-inset-bottom);
    font-size: $font-size-base;
    overflow-y: auto;
  }
}
</style>
